import React from "react"

const SequenceTime = ({ states, time, setTime }) => {
  const updateNumber = e => {
    if (e.target.value >= 0) setTime({ ...time, time: e.target.value })
  }

  const changeTimeType = e => {
    setTime({ ...time, timeType: e.target.value })
  }

  const updateWhen = e => {
    setTime({ ...time, timeWhen: e.target.value })
  }

  return (
    <>
      <span className="me-1">When?</span>
      <span className="me-1">
        <input
          name="seqSendMsgTime"
          type="number"
          className="form-control seqSendMsgTime"
          onChange={updateNumber}
          value={time.time}
        />
      </span>
      <span className="me-1">
        <select
          name="seqSendMsgTimeType"
          className="form-select seqSendMsgTimeType"
          value={time.timeType}
          onChange={changeTimeType}
        >
          <option value="minute">Minutes</option>
          <option value="hour">Hours</option>
          <option value="day">Days</option>
          <option value="week">Weeks</option>
          <option value="month">Months</option>
        </select>
      </span>
      <span className="me-1">
        <select
          name="seqSendRulesWhen"
          className="form-select seqSendRulesWhen"
          value={time.timeWhen}
          onChange={updateWhen}
        >
          {states.metaJson.automationLogic !== "AfterOnly" && <option value="before">before</option>}
          <option value="after">after</option>
        </select>
      </span>
      <span>
        {" "}
        {states.metaJson.automationLogic === "AfterOnly" && <span>added to </span>}
        {states.name}
      </span>
    </>
  )
}

export default SequenceTime
