import React, { useState, useEffect } from "react"
import Layout from "../../Layout/layout"
import Seo from "../../seo"
import { Form } from "react-bootstrap"
import AxiosInstance from "../../../services/axiosInstance"
import Detail from "./detail"
import MessagePanel from "./messagePanel"
import "./conversation.css"
import "../../../css/jquery.mCustomScrollbar.css"
import ResponseControl from "./responseControl/responseControl"
import "bootstrap/dist/css/bootstrap.min.css"

const Sequence = ({ fileUrl }) => {
  const [states, setStates] = useState({
    tags: [],
    messages: [],
    saveAutomation: false,
    fileUrl,
    metaJson: {},
    isLoaded: false,
  })

  const [responseControlData, setResponseControlData] = useState([])

  const submitSequence = e => {
    e.preventDefault()
    saveSequence()
  }

  const saveSequence = async e => {
    try {
      const { metaJson, tags, historyJson, name, eventsJson } = states
      const sequenceResponse = await AxiosInstance.post(`/drive/sequence/${fileUrl}`, {
        metaJson,
        tags,
        historyJson,
        name,
        eventsJson,
      })
      if (sequenceResponse.status === 202) {
        console.log("Update success")
      } else {
        console.error("Unexpected status code:", sequenceResponse.status)
      }
    } catch (error) {
      if (error.response) {
        console.error("Server Error:", error.response.data)
      } else if (error.request) {
        console.error("No response received:", error.request)
      } else {
        console.error("Request setup error:", error.message)
      }
    }
  }

  useEffect(() => {
    if (states.saveAutomation) {
      saveSequence()
      setStates({ ...states, saveAutomation: false })
    }
  }, [states.saveAutomation])

  useEffect(() => {
    getInitialData()
  }, [])

  const getInitialData = async () => {
    try {
      const sequenceResponse = await AxiosInstance.get(`/drive/sequence/${fileUrl}`)
      if (sequenceResponse.status === 200) {
        setSequenceInitialData(sequenceResponse.data.driveSequence)
      } else {
        console.error("Unexpected status code:", sequenceResponse.status)
      }
    } catch (error) {
      if (error.response) {
        console.error("Server Error:", error.response.data)
      } else if (error.request) {
        console.error("No response received:", error.request)
      } else {
        console.error("Request setup error:", error.message)
      }
    }
  }

  const setSequenceInitialData = sequence => {
    setStates({
      ...states,
      name: sequence.name,
      history: sequence.historyJson,
      metaJson: sequence.metaJson,
      eventsJson: sequence.eventsJson,
      messages: sequence.messages,
      tags: sequence.tags ?? [],
      sequenceId: sequence.id,
      fileUrl: fileUrl,
      isLoaded: true,
    })
  }

  return (
    <Layout>
      <Seo title={states.name ?? fileUrl} />
      <Form onSubmit={submitSequence} id={"docForm"}>
        <div id="right-section" className="h-100">
          <div className="row g-0" id="contactDetailTemplate">
            <div className="col" id="page-urls">
              <div className="row">
                <Detail states={states} setStates={setStates} submitMethod={submitSequence} />
                <MessagePanel
                  states={states}
                  setStates={setStates}
                  submitForm={submitSequence}
                  responseControlData={responseControlData}
                  setResponseControlData={setResponseControlData}
                />
              </div>
            </div>
          </div>
        </div>
      </Form>
      <ResponseControl
        states={states}
        setStates={setStates}
        data={responseControlData}
        setData={setResponseControlData}
      />
    </Layout>
  )
}

export default Sequence
