import React, { useState, useRef } from "react"

const HourInput = ({ className, value, setValue }) => {
  const [inputValue, setInputValue] = useState(value)
  const inputRef = useRef(null)

  const handleKeyUp = e => {
    let newValue = inputValue

    if (
      (inputValue[0] === "H" || inputValue.length === 0 || inputValue[4] !== "M") &&
      ["1", "2", "0"].includes(e.key)
    )
      newValue = e.key + "H:MM"
    else if (
      inputValue[1] === "H" &&
      ((inputValue[0] < "2" && e.key >= "0" && e.key <= "9") ||
        (inputValue[0] === "2" && e.key >= "0" && e.key <= "3"))
    )
      newValue = inputValue[0] + e.key + ":MM"
    else if (inputValue[1] !== "H" && inputValue[3] === "M" && e.key >= "0" && e.key <= "5") {
      newValue = updateCharAtIndex(inputValue, 3, e.key)
    } else if (inputValue[4] === "M" && inputValue[3] !== "M" && e.key >= "1" && e.key <= "9") {
      newValue = updateCharAtIndex(inputValue, 4, e.key)
      setValue(newValue)
    }

    if (newValue.indexOf("H") !== -1) {
      const position = newValue.indexOf("H")
      inputRef.current.setSelectionRange(position, position + 1)
    } else if (newValue.indexOf("M") !== -1) {
      const position = newValue.indexOf("M")
      inputRef.current.setSelectionRange(position, position + 1)
    }
    setInputValue(newValue)
  }

  const updateCharAtIndex = (inputString, index, newChar) => {
    if (index < 0 || index >= inputString.length) {
      return inputString
    }

    const charArray = inputString.split("")
    charArray[index] = newChar
    const updatedString = charArray.join("")

    return updatedString
  }

  return (
    <input
      type="text"
      placeholder={"HH:MM"}
      value={inputValue}
      onKeyUp={handleKeyUp}
      className={className}
      ref={inputRef}
      onChange={handleKeyUp}
    />
  )
}

export default HourInput
