import AxiosInstance from "../../../services/axiosInstance"
import { useState, useRef } from "react"

const useSharedLogic = (states, setStates) => {
  const [time, setTime] = useState({
      time: 0,
      timeType: "minute",
      timeWhen: "after",
    }),
    [subject, setSubject] = useState(""),
    [messageMain, setMessageMain] = useState(""),
    [name, setName] = useState(""),
    [cc, setCc] = useState(""),
    [bcc, setBcc] = useState(""),
    [selectedFiles, setSelectedFiles] = useState({}),
    [selectedFileType, setSelectedFileType] = useState("file")

  const messageMainRef = useRef(null)

  const saveMessage = async messageData => {
    const isEdit = states.selectedMessage !== undefined && states.selectedMessage !== 0

    const formData = new FormData()

    Object.keys(selectedFiles).forEach(key => {
      formData.append(key, selectedFiles[key])
    })

    Object.keys(messageData).forEach(key => {
      formData.append(key, key === "metaJson" ? JSON.stringify(messageData[key]) : messageData[key])
    })

    const messageUrl = isEdit
      ? `/drive/sequence/message/${states.selectedMessage}`
      : `/drive/sequence/${states.sequenceId}/message`
    try {
      const messageResponse = await AxiosInstance.post(messageUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      if ((messageResponse.status === 201 && !isEdit) || (messageResponse.status === 202 && isEdit)) {
        messageData.id = messageResponse.data.messageId

        setStates(prevStates => {
          let messages = [...prevStates.messages, messageData]
          if (isEdit) {
            messages = prevStates.messages.map(msg => {
              return msg.id === states.selectedMessage ? messageData : msg
            })
          }
          return { ...prevStates, messages, selectedMessage: 0 }
        })
        initStates()
      } else {
        console.error("Unexpected status code:", messageResponse.status)
      }
    } catch (error) {
      if (error.response) {
        console.error("Server Error:", error.response.data)
      } else if (error.request) {
        console.error("No response received:", error.request)
      } else {
        console.error("Request setup error:", error.message)
      }
    }
  }

  const injectVariable = e => {
    e.preventDefault()
    const start = messageMainRef.current.selectionStart
    const end = messageMainRef.current.selectionEnd
    const currentValue = messageMainRef.current.value
    const newText = `${currentValue.slice(0, start)}[${e.target.innerText}]${currentValue.slice(end)}`
    const newPosition = start + `[${e.target.innerText}]`.length
    messageMainRef.current.setSelectionRange(newPosition, newPosition)
    setMessageMain(newText)
  }

  const initStates = () => {
    setSubject("")
    setSubject("")
    setMessageMain("")
    setTime({
      time: 0,
      timeType: "minute",
      timeWhen: "after",
    })
    setCc("")
    setBcc("")
    setSelectedFiles({})
  }

  const selectMessage = message => {
    setSubject(message.subject)
    setMessageMain(message.messageMain)
    setTime(message.metaJson.time)
    setName(message.name)
    setCc(message.metaJson.cc)
    setBcc(message.metaJson.bcc)
  }

  const inputFileRef = useRef(null)

  const chooseFile = (e, fileType) => {
    e.preventDefault()

    inputFileRef.current.accept = ""
    let type = "file"

    if (fileType === "image") {
      inputFileRef.current.accept = "image/*"
      type = "imageFile"
    }

    if (fileType === "video") {
      inputFileRef.current.accept = "video/*"
      type = "videoFile"
    }

    setSelectedFileType(type)
    inputFileRef.current.click()
  }

  const updateSelectedFile = async e => {
    const file = e.target.files[0]

    if (file) {
      setSelectedFiles(prev => {
        return { ...prev, [selectedFileType]: file }
      })
    }
  }

  return {
    time,
    setTime,
    subject,
    setSubject,
    messageMain,
    setMessageMain,
    name,
    setName,
    saveMessage,
    selectMessage,
    injectVariable,
    messageMainRef,
    cc,
    setCc,
    bcc,
    setBcc,
    inputFileRef,
    chooseFile,
    updateSelectedFile,
  }
}

export default useSharedLogic
