import React, { useEffect, useState } from "react"
import MessageItem from "./messageItem"

const MessageList = ({ states, setStates, selectChannel, responseControlData, setResponseControlData }) => {
  const customSort = (a, b) => {
    const orderBefore = a.metaJson.time.timeWhen === "before" ? -1 : 1
    const orderAfter = a.metaJson.time.timeWhen === "after" ? -1 : 1

    if (a.metaJson.time.timeWhen !== b.metaJson.time.timeWhen) {
      return orderBefore - orderAfter
    }

    const typesOrder = ["month", "week", "day", "hour", "minute"]
    const typeComparison =
      typesOrder.indexOf(a.metaJson.time.timeType) - typesOrder.indexOf(b.metaJson.time.timeType)
    if (typeComparison !== 0) {
      return a.metaJson.time.timeWhen === "before" ? typeComparison : -typeComparison
    }

    const numberComparison = a.metaJson.time.time - b.metaJson.time.time
    return a.metaJson.time.timeWhen === "before" ? -numberComparison : numberComparison
  }

  const msgBefore = states.messages
    .filter(msg => {
      return msg?.metaJson?.time?.timeWhen === "before"
    })
    .sort(customSort)
  const msgAfter = states.messages
    .filter(msg => {
      return msg?.metaJson?.time?.timeWhen === "after"
    })
    .sort(customSort)

  useEffect(() => {}, [])

  const initResponseControl = (messageId, metaJson, e) => {
    e.preventDefault()
    setResponseControlData(prevStates => {
      return {
        ...prevStates,
        controls: metaJson?.responseControls,
        messageId: messageId,
        metaJson,
        fileUrl: states.fileUrl,
      }
    })
  }

  const addMessageButton = typeWhen => {
    return (
      <li className="chatDockAddNewMsgContainer">
        <div className="chat-msg">
          <div className="dropup btnChatDockHistoryAddAs ChatDockInputOpt">
            <button
              className="btn btnAddNewGreenDashed dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              onClick={e => setStates({ ...states, typeWhen })}
            >
              <i className="bi bi-plus-lg"></i>
            </button>
            <ul className="dropdown-menu ui-sortable">
              <li>
                <a className="dropdown-item" data-type="Chat" data-value="" href="#" onClick={selectChannel}>
                  <i className="bi bi-chat-dots-fill"></i> Chat{" "}
                </a>
              </li>
              <li>
                <a className="dropdown-item" data-type="Email" data-value="" href="#" onClick={selectChannel}>
                  <i className="bi bi-envelope-fill"></i> Email{" "}
                </a>
              </li>
              <li>
                <a className="dropdown-item" data-type="SMS" data-value="" href="#" onClick={selectChannel}>
                  <i className="bi bi-phone-fill"></i> SMS{" "}
                </a>
              </li>
              <li>
                <a className="dropdown-item" data-type="Call" data-value="" href="#" onClick={selectChannel}>
                  <i className="bi bi-telephone-fill"></i> Call{" "}
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  data-type=""
                  data-value=""
                  href="#"
                  onClick={e => initResponseControl(0, states.metaJson, e)}
                >
                  <i className="bi bi-gear-fill"></i> Response Control{" "}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </li>
    )
  }

  const renderMessages = messages => {
    return messages.map(msg => {
      return (
        <MessageItem
          message={msg}
          key={`${msg.id}-main`}
          states={states}
          setStates={setStates}
          responseControlData={responseControlData}
          setResponseControlData={setResponseControlData}
          initResponseControl={initResponseControl}
        />
      )
    })
  }

  return (
    <ul className="chat ui-sortable">
      <li className="chatDockInitBox">
        <div className="chat-msg">
          <div className="chatDockInitBoxText chatDockInitBoxStart">
            <div className="chatDockInitBoxStartContainer">
              <span className="chatDockInitBoxStartIcon">
                <i className="bi bi-download"></i>
              </span>
              <span>Added to "{states.name}"</span>
            </div>
          </div>
        </div>
      </li>
      <li className="self chatDockAfterEventBox chatDockResponseControlContainer">
        <div className="chat-msg">
          <div className="avatar">
            <a
              href="#"
              className="d-block"
              data-bs-toggle="tooltip"
              title=""
              data-bs-original-title="Future Recipient"
              aria-label="Future Recipient"
            >
              <img
                src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/avatar.gif`}
                className="mCS_img_loaded"
              />
            </a>
          </div>
          <div className="msg">
            <div
              className="input-group ResponseControlInputGroup"
              onClick={e => initResponseControl(0, states.metaJson, e)}
            >
              <span className="input-group-text">
                <i className="bi bi-reply-fill"></i>
              </span>
              <p className="chatDockResponseControlBoxText">
                Response Controls <br />
                <span className="simpleText">Anytime during this conversation</span>
              </p>
            </div>
          </div>
        </div>
      </li>
      {renderMessages(msgBefore)}

      {states.metaJson.automationLogic !== "AfterOnly" && (
        <>
          {addMessageButton("before")}

          <li className="chatDockInitBox chatDockAfterEventBox">
            <div className="chat-msg">
              <div className="chatDockInitBoxText chatDockInitBoxStart">
                <div className="chatDockInitBoxStartContainer">
                  <span className="chatDockInitBoxStartIcon">
                    <i className="bi bi-calendar3"></i>
                  </span>
                  <span>{states?.eventsJson?.mainEvent?.title ?? "Event"}</span>
                </div>
              </div>
            </div>
          </li>
        </>
      )}

      {renderMessages(msgAfter)}

      {addMessageButton("after")}

      <li className="chatDockInitBox">
        <div className="chat-msg">
          <div className="chatDockInitBoxText chatDockInitBoxEnd">
            <div className="chatDockInitBoxEndContainer">
              <span className="chatDockInitBoxEndIcon">
                <i className="bi bi-stop-fill"></i>
              </span>
              <span>End "{states?.name ?? ""}"</span>
            </div>
          </div>
        </div>
      </li>
    </ul>
  )
}

export default MessageList
