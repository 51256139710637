import React, { useEffect, useState, useRef } from "react"
import SequenceTime from "./sequenceTime"
import useSharedLogic from "../customHook"
import AxiosInstance from "../../../../services/axiosInstance"

const EmailDock = ({ states, setStates }) => {
  const {
    time,
    setTime,
    subject,
    setSubject,
    messageMain,
    setMessageMain,
    cc,
    setCc,
    bcc,
    setBcc,
    saveMessage,
    selectMessage,
    injectVariable,
    messageMainRef,
    inputFileRef,
    chooseFile,
    updateSelectedFile,
  } = useSharedLogic(states, setStates)
  const [primaryEmail, setPrimaryEmail] = useState([]),
    [emailFrom, setEmailFrom] = useState(""),
    [emailFormat, setEmailFormat] = useState("Default"),
    [showAddCc, setShowAddCc] = useState(false),
    [showAddBcc, setShowAddBcc] = useState(false)

  useEffect(() => {
    if (states.selectedMessage === 0) {
      setTime(time => {
        return { ...time, timeWhen: states.typeWhen }
      })
      return
    }

    const message = states.messages.filter(msg => {
      return msg.id === states.selectedMessage
    })[0]
    selectMessage(message)
    setEmailFrom(message?.metaJson?.from)
  }, [states.selectedMessage])

  const addMessage = () => {
    const messageData = {
      subject,
      name: subject,
      messageMain,
      metaJson: {
        time,
        emailFormat,
        cc,
        bcc,
        emailAs: "default",
        from: emailFrom,
      },
      msgType: "email",
    }
    saveMessage(messageData)
    setEmailFrom("")
    closeCcBcc()
  }

  const getPrimaryEmail = async () => {
    try {
      const response = await AxiosInstance.get("/settings/user/emails")
      if (response.status === 200) {
        if (response.data.emails.length > 0) {
          setPrimaryEmail(response.data.emails)
        } else {
          setPrimaryEmail(["noreply@SwiftCRM.com", "Connect Email Sender"])
        }
      } else {
        console.error("Unexpected status code:", response.status)
      }
    } catch (error) {
      if (error.response) {
        console.error("Server Error:", error.response.data)
      } else if (error.request) {
        console.error("No response received:", error.request)
      } else {
        console.error("Setup error:", error.message)
      }
    }
  }

  const updateEmailFrom = e => {
    setEmailFrom(e.target.value)
  }

  useEffect(() => {
    getPrimaryEmail()
  }, [])

  const updateFormat = (format, e) => {
    e.preventDefault()
    setEmailFormat(format)
  }

  const addCc = e => {
    e.preventDefault()
    closeCcBcc()
    setShowAddCc(true)
  }

  const addBcc = e => {
    closeCcBcc()
    e.preventDefault()
    setShowAddBcc(true)
  }

  const closeCcBcc = e => {
    if (e) e.preventDefault()
    setShowAddBcc(false)
    setShowAddCc(false)
  }

  const updateEmailDest = (setter, e) => {
    setter(e.target.value)
  }

  return (
    <div className="ChatDockOpt ChatDockEmailOpt">
      <div className="mb-2 ChatDockEmailToOpt w-100">
        <div className="row align-items-center">
          <div className="col-sm-4">
            <div className="d-inline-block me-1">
              <button
                className="btn btnAddNewGreenDashed"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span data-bs-toggle="tooltip" title="" data-bs-original-title="AImo" aria-label="AImo">
                  <i className="bi bi-robot"></i>
                </span>
              </button>
              <ul className="dropdown-menu scrollable-menu">
                <li>
                  <strong className="dropdown-item-text">== Email ==</strong>
                </li>
                <li>
                  <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                    AIDA - Attn, Interest, Desire, Action
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                    Pain, Agitate, Solve
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                    Cold Email
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                    Email Newsletter
                  </a>
                </li>
                <li>
                  <strong className="dropdown-item-text">== ARTICLES &amp; BLOG ==</strong>
                </li>
                <li>
                  <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                    Article Writer Collab Style
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                    Article Writer Title Dependent
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                    Article Rewriter (English)
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                    Article Outliner
                  </a>
                </li>
                <li>
                  <strong className="dropdown-item-text">== SOCIAL MEDIA ==</strong>
                </li>
                <li>
                  <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                    Twitter Tweets
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                    LinkedIn Posts
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                    Instagram Captions
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                    YouTube Helper
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                    TikTok Helper
                  </a>
                </li>
              </ul>
            </div>
            <div className="d-inline-block me-1">
              <button
                className="btn btnAddNewGreenDashed"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span
                  data-bs-toggle="tooltip"
                  title=""
                  data-bs-original-title="Templates"
                  aria-label="Templates"
                >
                  <i className="bi bi-journals"></i>
                </span>
              </button>
              <ul className="dropdown-menu scrollable-menu chatDockEmailDocs">
                <li>
                  <a className="dropdown-item" href="#">
                    Doc 1
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Doc 2
                  </a>
                </li>
              </ul>
            </div>
            <div className="d-inline-block me-1">
              <button
                className="btn btnAddNewGreenDashed"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span
                  data-bs-toggle="tooltip"
                  title=""
                  data-bs-original-title="Snippets"
                  aria-label="Snippets"
                >
                  <i className="bi bi-pencil-square"></i>
                </span>
              </button>
              <ul className="dropdown-menu scrollable-menu chatDockEmailSnippets">
                <li>
                  <a className="dropdown-item" href="#">
                    Snippets 1
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Snippets 2
                  </a>
                </li>
              </ul>
            </div>
            <div className="d-inline-block me-1">
              <button
                className="btn btnAddNewGreenDashed"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span
                  data-bs-toggle="tooltip"
                  title=""
                  data-bs-original-title="Inject Recipient Variable i.e. Firstname"
                  aria-label="Inject Recipient Variable i.e. Firstname"
                >
                  <i className="bi bi-person-bounding-box"></i>
                </span>
              </button>
              <ul className="dropdown-menu scrollable-menu chatDockRecipientVariables">
                <li>
                  <a className="dropdown-item" href="#" onClick={injectVariable}>
                    Firstname_FirstCap
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-8">
            <div className="chatDockEmailViaCcBccControls">
              <div className="chatDockEmailViaControls">
                <div>via</div>
                <select
                  name="ChatDockEmailUsing"
                  id="ChatDockEmailUsing"
                  className="form-select invisibleField"
                  value={emailFrom}
                  onChange={updateEmailFrom}
                >
                  {primaryEmail.length > 0 &&
                    primaryEmail.map((email, index) => {
                      return (
                        <option key={`user_email_i_${index}`} value={email}>
                          {email}
                        </option>
                      )
                    })}
                </select>
              </div>
              <div className="chatDockEmailCcBccControls">
                <div className="col-auto d-inline-block ChatDockEmailAs">
                  <div className="d-inline-block me-1">
                    <button
                      className="btn btn-default"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span
                        data-bs-toggle="tooltip"
                        title=""
                        data-bs-original-title="Default"
                        aria-label="Default"
                      >
                        <i className="bi bi-envelope-fill"></i>
                      </span>
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="#" onClick={e => updateFormat("default", e)}>
                          <i className="bi bi-envelope-fill"></i> Default{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={e => updateFormat("secure message", e)}
                        >
                          <i className="bi bi-lock-fill"></i> Secure Message (Login Required){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={e => updateFormat("branded newsletter", e)}
                        >
                          <i className="bi bi-code-slash"></i> Branded Newsletter{" "}
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#" onClick={e => updateFormat("plain", e)}>
                          <i className="bi bi-list"></i> Plain Text{" "}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-auto d-inline-block ChatDockEmailCcBcc">
                  <div className="d-inline-block">
                    <button
                      className="btn btn-default"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span
                        data-bs-toggle="tooltip"
                        title=""
                        data-bs-original-title="Add Cc/Bcc"
                        aria-label="Add Cc/Bcc"
                      >
                        <i className="bi bi-plus-lg"></i>
                      </span>
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="#" onClick={addCc}>
                          <i className="bi bi-envelope-fill"></i> Add Cc{" "}
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#" onClick={addBcc}>
                          <i className="bi bi-envelope-fill"></i> Add Bcc{" "}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {(showAddCc || showAddBcc) && (
                  <div className="col-auto ChatDockEmailCcBccInput position-relative">
                    <a href="#" className="btnRemoveChatDockEmailCcBccInput" onClick={closeCcBcc}>
                      <i className="bi bi-x-lg"></i>
                    </a>
                    {showAddCc && (
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter (cc) email address"
                        value={cc}
                        onChange={e => updateEmailDest(setCc, e)}
                      />
                    )}
                    {showAddBcc && (
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter (bcc) email address"
                        value={bcc}
                        onChange={e => updateEmailDest(setBcc, e)}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-2">
        <div className="d-flex align-items-center">
          <SequenceTime states={states} time={time} setTime={setTime} />
        </div>
      </div>
      <div className="mb-2">
        <input
          type="text"
          className="form-control"
          placeholder="Subject"
          value={subject}
          onChange={e => setSubject(e.target.value)}
        />
      </div>
      <div className="mb-2">
        <textarea
          ref={messageMainRef}
          className="form-control ChatDockEmailBox"
          placeholder="Message"
          rows="5"
          onChange={e => setMessageMain(e.target.value)}
          value={messageMain}
        ></textarea>
      </div>
      <div className="mb-2 text-end">
        <div className="row">
          <div className="col-sm-8 text-start ChatDockPrivateNoteTools">
            <div className="dropdown d-inline-block btnGroupChatDockVideoMail">
              <button
                className="dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i
                  data-bs-toggle="tooltip"
                  title=""
                  className="bi bi-camera-video-fill"
                  data-bs-original-title="Video Mail"
                  aria-label="Video Mail"
                ></i>
              </button>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="#chatDockVideoMail" data-bs-toggle="modal">
                    <i className="bi bi-play-fill"></i> Select Existing / Template{" "}
                  </a>
                </li>
                <li>
                  <a className="dropdown-item btnChatDockVideoRecording" href="#">
                    <i className="bi bi-record-fill"></i> Record New{" "}
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item btnChatDockVideoMailUpload"
                    href="#"
                    onClick={e => chooseFile(e, "video")}
                  >
                    <i className="bi bi-cloud-arrow-up-fill"></i> Upload{" "}
                  </a>
                </li>
              </ul>
            </div>
            <a href="#chatDockURLInjector" data-bs-toggle="modal">
              <i
                data-bs-toggle="tooltip"
                title=""
                className="bi bi-link-45deg"
                data-bs-original-title="Add URL"
                aria-label="Add URL"
              ></i>
            </a>
            <a href="#Global_Uploader" onClick={e => chooseFile(e, "image")}>
              <i
                data-bs-toggle="tooltip"
                title=""
                className="bi bi-camera-fill"
                data-bs-original-title="Insert Image"
                aria-label="Insert Image"
              ></i>
            </a>
            <a
              href="#"
              className="chatNoteImg"
              data-bs-toggle="tooltip"
              title=""
              data-bs-original-title="Upload file"
              aria-label="Upload file"
              onClick={e => chooseFile(e, "file")}
            >
              <i className="bi bi-paperclip"></i>
            </a>
            <a
              href="#"
              id="ChatDockEmailEmoji"
              data-bs-toggle="tooltip"
              title=""
              data-bs-original-title="Inject Emoji"
              aria-label="Inject Emoji"
            >
              <i className="bi bi-emoji-smile"></i>
            </a>
            <span className="d-none">
              <input
                type="file"
                accept="image/*"
                className="chatNoteImgFile"
                ref={inputFileRef}
                onChange={updateSelectedFile}
              />
            </span>{" "}
          </div>
          <div className="col-sm-4 text-end">
            <button type="button" className="btn btn-primary btnChatDockSendEmail" onClick={addMessage}>
              <i className="bi bi-check2"></i> Save{" "}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmailDock
