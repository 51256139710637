import React, { useState, useEffect } from "react"
import AxiosInstance from "../../../services/axiosInstance"

const AutomationLogic = ({ states, setStates, triggerSave }) => {
  const [events, setEvents] = useState([]),
    [automationLogic, setAutomationLogic] = useState(""),
    [mainEvent, setMainEvent] = useState({}),
    [task, setTask] = useState({})

  const updateAutomationLogic = e => {
    if (e.target.checked) {
      setAutomationLogic(e.target.value)
    }
  }

  const loadInitialData = async () => {
    try {
      const eventsResponse = await AxiosInstance.get(`/calendar/?from_date=2024-01-01`)
      if (eventsResponse.status === 200) {
        setEvents(
          eventsResponse.data.items.map(event => {
            return { id: event.id, title: event.title }
          })
        )
      } else {
        console.error("Unexpected status code:", eventsResponse.status)
      }
    } catch (error) {
      if (error.response) {
        console.error("Server Error:", error.response.data)
      } else if (error.request) {
        console.error("No response received:", error.request)
      } else {
        console.error("Request setup error:", error.message)
      }
    }
  }

  useEffect(() => {
    loadInitialData()
  }, [])

  const changeEvent = e => {
    if (e.target.value !== "") {
      const selectedEvent = events.filter(event => {
        return event.id == e.target.value
      })[0]
      setMainEvent(selectedEvent)
    }
  }

  const changeInputRadio = e => {}

  const saveAutomationLogic = e => {
    e.preventDefault()
    if (
      (automationLogic === "BeforeAfter" && Object.keys(mainEvent).length !== 0) ||
      (automationLogic === "WhatWeKnow" && Object.keys(task).length !== 0) ||
      automationLogic === "AfterOnly"
    ) {
      setStates({
        ...states,
        eventsJson: { ...states.eventsJson, mainEvent },
        metaJson: { ...states.metaJson, automationLogic },
        saveAutomation: true,
      })
    }
  }

  return (
    <div className="ChatDockConversationSequenceStep1">
      <div className="colHeading">
        <strong>
          <i className="bi bi-gear-fill"></i> AUTOMATION LOGIC: When should we fire these events?{" "}
        </strong>
      </div>
      <div className="dh-block-body">
        <div className="mb-1">
          <input
            type="radio"
            name="ChatDockConversationAutomationLogicInput"
            id="S2"
            className="css-checkbox"
            value="AfterOnly"
            onClick={updateAutomationLogic}
            onChange={changeInputRadio}
            checked={automationLogic === "AfterOnly"}
          />
          <label htmlFor="S2" className="css-radio-label">
            <i className="bi bi-three-dots"></i> IN SEQUENCE: Autoresponder / Drip Marketing Style{" "}
            <span
              data-bs-toggle="tooltip"
              title="Example: Sales Lead Nurturing, Marketing Automation, Employee Onboarding. All messages flow downstream from when the recipient is added to this sequence."
            >
              <i className="bi bi-question-circle-fill"></i>
            </span>
          </label>
        </div>
        <div className="mb-1">
          <input
            type="radio"
            name="ChatDockConversationAutomationLogicInput"
            id="S1"
            className="css-checkbox"
            value="BeforeAfter"
            onClick={updateAutomationLogic}
            onChange={changeInputRadio}
            checked={automationLogic === "BeforeAfter"}
          />
          <label htmlFor="S1" className="css-radio-label">
            <i className="bi bi-calendar3"></i> BY APPOINTMENT: Before &amp; After an Event{" "}
            <span
              data-bs-toggle="tooltip"
              title="Example: Sales appointment, solar installation, dental appoiAppointment Style (before &amp; after an event)ntment. Messages can fire before date-time X and after date-time X. The date &amp; time of the appointment is added when the user-recipient is added to this sequence, so you can use one list for many receipients."
            >
              <i className="bi bi-question-circle-fill"></i>
            </span>
          </label>
        </div>
        {automationLogic === "BeforeAfter" && (
          <div className="mb-3 ChatDockConversationSequenceBeforeAfterOpt">
            <label className="form-label">
              Event Type Name / Title{" "}
              <span
                data-bs-toggle="tooltip"
                title="i.e. Sales Webinar, Office Appointment, Meeting"
                data-bs-original-title=""
              >
                <i className="bi bi-question-circle-fill"></i>
              </span>
            </label>
            <select className="form-select" onChange={changeEvent} value={mainEvent?.id}>
              <option value="">== SCHEDULER SERVICE / EVENT TYPE ==</option>
              {events.map((event, index) => {
                return (
                  <option key={index} value={event.id}>
                    {event.title}
                  </option>
                )
              })}
            </select>
          </div>
        )}
        <div className="mb-1">
          <input
            type="radio"
            name="ChatDockConversationAutomationLogicInput"
            id="S3"
            className="css-checkbox"
            value="WhatWeKnow"
            onClick={updateAutomationLogic}
            onChange={changeInputRadio}
            checked={automationLogic === "WhatWeKnow"}
          />
          <label htmlFor="S3" className="css-radio-label">
            <i className="bi bi-robot"></i> BY WHAT WE KNOW: Learn facts &amp; obtain assets{" "}
            <span
              data-bs-toggle="tooltip"
              title="Will use AImo to request deliverables or learn key data, in any order, conversational style and via whatever channels possible."
              data-bs-original-title=""
            >
              <i className="bi bi-question-circle-fill"></i>
            </span>
          </label>
        </div>
        {automationLogic === "WhatWeKnow" && (
          <div className="mb-3 ChatDockConversationSequenceWhatWeKnowOpt">
            <select className="form-select">
              <option>== Project / Job / Sales Opportunity ==</option>
            </select>
          </div>
        )}
        <div className="text-end ChatDockConversationSequenceSaveRow">
          <button className="btn btn-primary btnSaveConversationSequence" onClick={saveAutomationLogic}>
            <i className="bi bi-check2"></i> Save / Add{" "}
          </button>
        </div>
      </div>
    </div>
  )
}

export default AutomationLogic
