import * as React from "react"
import moment from "moment"

import AxiosInstance from "../../../services/axiosInstance"
import { useState, useEffect } from "react"
import { Modal } from "react-bootstrap"

const AddContactGroupModal = ({ showContactGroupModal, setShowContactGroupModal, sequenceId }) => {
  const [contactGroupId, setContactGroupId] = useState(""),
    [contactGroupSearchResult, setContactGroupSearchResult] = useState([])

  const handleSubmit = () => {
    if (contactGroupId === "") {
      alert("Please select Contact Group.")
      return false
    }

    return new Promise(async (resolve, reject) => {
      try {
        await AxiosInstance.post(
          `drive/sequence/assign-sequence-contact-group/${sequenceId}/${contactGroupId}`
        )
        setContactGroupId("")
      } catch (error) {
        console.error(error)
        reject(error)
      }
    })
  }

  const handleClose = () => {
    setShowContactGroupModal(false)
  }

  const contactGroupSearchData = e => {
    AxiosInstance.get(`/contact/group`, {
      params: {
        page: 1,
      },
    }).then(response => {
      const displayedItems = response.data.items.slice(0, 10)
      setContactGroupSearchResult(displayedItems)
    })
  }

  useEffect(() => {
    if (showContactGroupModal) {
      contactGroupSearchData()
    }
  }, [showContactGroupModal])

  return (
    <Modal show={showContactGroupModal} id="addNewContactModal">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="addListOfPeopleModalLabel">
          <i className="bi bi-table" /> Add an Audience / Group
        </h1>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={() => handleClose()}
        />
      </div>
      <div className="modal-body">
        <div>
          <select
            className="form-select"
            value={contactGroupId}
            onChange={e => {
              setContactGroupId(e.target.value)
            }}
          >
            <option value={""}>== Audience / Group ==</option>
            {contactGroupSearchResult.map(groupvalue => {
              return (
                <option key={groupvalue.id} value={groupvalue.id}>
                  {groupvalue.name}
                </option>
              )
            })}
          </select>
          <div id="emailHelp" className="form-text text-dark">
            This will create users from contact info if they don't exist, and add them to this new workroom.
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="submit"
          className="btn btn-default"
          data-bs-dismiss="modal"
          onClick={() => handleClose()}
        >
          <i className="bi bi-x-lg" /> Close
        </button>
        <button type="button" className="btn btn-primary" name="assignContactGroup" onClick={handleSubmit}>
          <i className="bi bi-check2" /> Save &amp; Add <i className="bi bi-person-fill-add" />
        </button>
      </div>
    </Modal>
  )
}

export default AddContactGroupModal
