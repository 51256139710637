import React, { useState, useEffect } from "react"
import ResponseControlItem from "./responseControlItem"
import AxiosInstance from "../../../../services/axiosInstance"
import { Modal } from "react-bootstrap"

const ResponseControl = ({ states, setStates, data, setData }) => {
  const [rControls, setControls] = useState(data?.controls ?? []),
    [showRc, setShowRc] = useState(false)

  useEffect(() => {
    if (data.messageId !== null && data.messageId !== undefined) {
      setControls(data?.controls ?? [])
      setShowRc(true)
    }
  }, [data.messageId])

  const addRc = e => {
    e.preventDefault()
    setControls([...rControls, { replyTrigger: "", thenBlocks: [] }])
  }

  const saveResponseControl = async e => {
    e.preventDefault()
    let url, updateData

    if (data.messageId === 0) {
      url = `drive/sequence/${data.fileUrl}`
      updateData = {
        metaJson: { ...data.metaJson, responseControls: rControls },
        name: states.name,
      }
    } else {
      url = `drive/sequence/message/${data.messageId}`
      const message = states.messages.filter(msg => {
        return msg.id === data.messageId
      })[0]
      updateData = {
        metaJson: { ...data.metaJson, responseControls: rControls },
        messageMain: message.messageMain,
      }
    }
    try {
      const response = await AxiosInstance.post(url, updateData)
      if (response.status === 202) {
        console.log("Response control update succes")
        if (data.messageId !== 0)
          setStates(
            states.messages.map(msg => {
              return msg.id == data.messageId ? { ...msg, ...updateData } : msg
            })
          )
        else setStates({ ...states, metaJson: { ...data.metaJson, responseControls: rControls } })

        handleClose()
      } else {
        console.error("Unexpected status code:", response.status)
      }
    } catch (error) {
      if (error.response) {
        console.error("Server Error:", error.response.data)
      } else if (error.request) {
        console.error("No response received:", error.request)
      } else {
        console.error("Request setup error:", error.message)
      }
    }
  }

  const deleteRcBlock = (index, e) => {
    e.preventDefault()
    setControls(
      rControls.filter((control, i) => {
        return i !== index
      })
    )
  }

  const handleClose = () => {
    setShowRc(false)
    setData({ ...data, messageId: null })
  }

  return (
    <Modal
      show={showRc}
      onHide={handleClose}
      aria-labelledby="responseControlModalTitle"
      aria-hidden="true"
      id="responseControlModal"
      size="lg"
      tabIndex="-1"
      role="dialog"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h1 className="modal-title fs-5">
            <i className="bi bi-reply-fill"></i> Response Controls
          </h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="panel panel-default">
          <div className="panel-heading">
            <h3 className="panel-title">
              <a
                data-bs-toggle="collapse"
                href="#URLInj_blue"
                role="button"
                aria-expanded="true"
                aria-controls="URLInj_blue"
                className=""
              >
                If Future Recipient replies with...
              </a>
            </h3>
          </div>

          <div id="URLInj_blue" className="panel-collapse collapse show">
            <div className="panel-body">
              {rControls.map((control, index) => {
                return (
                  <ResponseControlItem
                    key={`${index}-${data.messageId}`}
                    block={control}
                    setRc={setControls}
                    messages={states.messages}
                    index={index}
                    deleteRcBlock={deleteRcBlock}
                  />
                )
              })}

              <div className="row g-0 btnAddNewResponseRuleRow">
                <div className="col-lg-12 text-center">
                  <a
                    href="#"
                    onClick={addRc}
                    className="btnAddNewGreenDashed btnAddNewResponseRule"
                    data-bs-toggle="tooltip"
                    title=""
                    data-bs-original-title="Add New"
                    aria-label="Add New"
                    aria-describedby="tooltip660431"
                  >
                    <i className="bi bi-plus-lg"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-default" data-bs-dismiss="modal" onClick={handleClose}>
          <i className="bi bi-x-lg"></i> Close{" "}
        </button>
        <button type="button" className="btn btn-primary" onClick={saveResponseControl}>
          <i className="bi bi-check2"></i> Save{" "}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default ResponseControl
