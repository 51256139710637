import * as React from "react"
import PrivateRoute from "../../../components/privateRoute"
import Sequence from "../../../components/Drive/Sequence/sequence"
import ReduxWrapper from "../../../redux/reduxWrapper"

const SequencePage = props => {
  return <PrivateRoute component={Sequence} location={props.location} fileUrl={props.fileUrl} />
}

const WrappedPage = props => <ReduxWrapper element={<SequencePage {...props} />} />
export default WrappedPage
