import React, { useState, useEffect } from "react"
import ThenBlock from "./thenBlock"

const ResponseControlItem = ({ index, block, setRc, messages, deleteRcBlock }) => {
  const [replyTrigger, setReplyTrigger] = useState(""),
    [thenBlocks, setThenBlocks] = useState([])

  const updateReplyTrigger = e => {
    setReplyTrigger(e.target.value)
    setRc(prev => {
      return prev.map((rc, i) => (i === index ? { ...block, replyTrigger: e.target.value } : rc))
    })
  }

  const addThen = e => {
    e.preventDefault()
    setThenBlocks([...thenBlocks, { action: "", branching: "Yes" }])
  }

  useEffect(() => {
    setThenBlocks(block.thenBlocks)
    setReplyTrigger(block.replyTrigger)
  }, [])

  useEffect(() => {
    setRc(prev => {
      return prev.map((b, i) => {
        return i === index ? { ...block, thenBlocks } : b
      })
    })
  }, [thenBlocks])

  const deleteThenBlock = (index, e) => {
    e.preventDefault()
    setThenBlocks(
      thenBlocks.filter((thenBlock, i) => {
        return i !== index
      })
    )
  }

  return (
    <div className="row g-0 mb-3 blueOutlineBox">
      <button
        className="btnRemoveFYOption"
        data-bs-toggle="tooltip"
        title=""
        data-bs-original-title="Remove"
        aria-label="Remove"
        onClick={e => deleteRcBlock(index, e)}
      >
        <i className="bi bi-x-lg"></i>
      </button>
      <div className="col-lg-12">
        <input
          type="text"
          className="form-control"
          placeholder="C, Confirm, Cnfrm, Confrm"
          value={replyTrigger}
          onChange={updateReplyTrigger}
        />
      </div>
      {thenBlocks.map((thenBlock, index) => {
        return (
          <ThenBlock
            key={`${index}${thenBlock?.action}`}
            index={index}
            deleteThenBlock={deleteThenBlock}
            thenBlock={thenBlock}
            setThenBlocks={setThenBlocks}
            messages={messages}
          />
        )
      })}

      <a href="#" className="btnAddNewResponseRuleCondition" onClick={addThen}>
        <i className="bi bi-plus-lg"></i>
      </a>
    </div>
  )
}

export default ResponseControlItem
