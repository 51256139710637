import React, { useState, useEffect } from "react"
import DatePicker from "react-datepicker"
import Tags from "../Doc/MoreOptions/tags"
import HourInput from "../../Global/hourInput"
import AxiosInstance from "../../../services/axiosInstance"
import { getUserName } from "../Doc/Service/docHelper"
import "react-datepicker/dist/react-datepicker.css"
import "bootstrap/dist/css/bootstrap.min.css"
import { Link } from "gatsby"
import AddContactModal from "./addContactModel"
import AddContactGroupModal from "./addContactGroupModal"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

const Detail = ({ states, setStates, submitMethod }) => {
  const [showRecurrenceBox, setShowRecurrenceBox] = useState(false)
  const [languages, setLanguages] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [sequenceId, setSequenceId] = useState("")
  const [showContactGroupModal, setShowContactGroupModal] = useState(false)

  const updateName = e => {
    setStates({ ...states, name: e.target.value })
  }

  const updateLanguage = e => {
    setStates({ ...states, metaJson: { ...states.metaJson, language: e.target.value } })
  }

  useEffect(() => {
    setShowRecurrenceBox(states.metaJson?.triggerLogic === "2")
  }, [states.metaJson?.triggerLogic])

  const setTags = tags => {
    setStates({ ...states, tags })
  }

  const getLanguages = async () => {
    try {
      const languageResponse = await AxiosInstance.get(`${process.env.GATSBY_API_URL}/language/list`)
      if (languageResponse.status === 200) {
        setLanguages(languageResponse.data.data)
      } else {
        console.error("Unexpected status code:", languageResponse.status)
      }
    } catch (error) {
      if (error.response) {
        console.error("Server Error:", error.response.data)
      } else if (error.request) {
        console.error("No response received:", error.request)
      } else {
        console.error("Request setup error:", error.message)
      }
    }
  }

  useEffect(() => {
    getLanguages()
  }, [])

  const seePreview = e => {
    e.preventDefault()
  }

  return (
    <div className="col-lg-6 col-md-12 contactDetailLeftSection">
      <div className="row g-0 align-items-center">
        <div className="col-sm-10 mb-3">
          <div className="pageTitle_Editor">
            <Link to="/drive" className="goBack">
              <i className="bi bi-arrow-90deg-left" />
            </Link>
            <button type="button" className="CD_UserType isHuman" data-bs-toggle="tooltip">
              <i className="bi bi-three-dots" />
            </button>
            <input
              type="text"
              className="form-control"
              placeholder="Untitled Conversation"
              value={states.name ?? ""}
              onChange={updateName}
            />
          </div>
        </div>
        <div className="col-sm-2 text-end mb-3">
          <div className="d-inline-block dropup">
            <OverlayTrigger overlay={<Tooltip>Add People</Tooltip>}>
              <button
                className="btn btnAddNewGreenDashed btnAddNewUser"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span data-bs-toggle="tooltip">
                  <i className="bi bi-person-fill-add"></i>
                </span>
              </button>
            </OverlayTrigger>

            <ul className="dropdown-menu scrollable-menu">
              <li>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() => {
                    setShowModal(true)
                  }}
                >
                  <i className="bi bi-person-fill-add"></i> Add Someone{" "}
                </a>
              </li>

              <li>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() => {
                    setShowContactGroupModal(true)
                  }}
                >
                  <i className="bi bi-people-fill"></i> Add an Audience / Group{" "}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="row g-0">
        <div className="col-lg-12 mb-3 accordion" id="more_panel">
          <div className="accordion-item panel panel-grey">
            <div
              className="accordion-button collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#more_container"
              aria-expanded="true"
              aria-controls="more_container"
            >
              <span className="panel-heading-icon">
                <i className="bi bi-three-dots"></i>
              </span>
              <span className="panel-heading-title">More Options</span>
            </div>
            <div id="more_container" className="accordion-collapse collapse" aria-labelledby="more_container">
              <div className="accordion-body">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-lg-12 mb-3">
                        <div className="colHeading">
                          <strong>
                            <i className="bi bi-tags-fill"></i> Tags{" "}
                          </strong>
                        </div>
                        <div className="dh-block-body">
                          <Tags
                            tags={states.tags}
                            setTags={setTags}
                            tempTags={states.tags}
                            setTempTags={setTags}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-lg-12 mb-3">
                        <div className="colHeading">
                          <strong>
                            <i className="bi bi-globe"></i> Language{" "}
                          </strong>
                        </div>
                        <div className="dh-block-body">
                          <select
                            name="Language"
                            id="Language"
                            className="form-select"
                            onChange={updateLanguage}
                            value={states.metaJson?.language}
                          >
                            {languages.map(languagesData => {
                              return (
                                <option value={languagesData.code} key={`languagesData_${languagesData.id}`}>
                                  {languagesData.language}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <AddContactModal showModal={showModal} setShowModal={setShowModal} sequenceId={states.sequenceId} />

        <AddContactGroupModal
          showContactGroupModal={showContactGroupModal}
          setShowContactGroupModal={setShowContactGroupModal}
          sequenceId={states.sequenceId}
        />

        <div className="col-lg-12 text-center">
          <button type="button" className="btn btn-primary btn-lg" onClick={submitMethod}>
            <i className="bi bi-check2"></i> Save Changes{" "}
          </button>{" "}
          <a
            href={`${process.env.GATSBY_PREVIEW_URL}/${getUserName()}/sequence/${states.fileUrl}`}
            type="button"
            className="btn btn-secondary btn-lg"
            target="_blank"
            onClick={seePreview}
          >
            <i className="bi bi-box-arrow-up-right" /> Preview
          </a>{" "}
          <div className="d-inline-block dropup">
            <OverlayTrigger overlay={<Tooltip>Add People</Tooltip>}>
              <button
                className="btn btn-success btn-lg"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span data-bs-toggle="tooltip">
                  <i className="bi bi-person-fill-add"></i>
                </span>
              </button>
            </OverlayTrigger>
            <ul className="dropdown-menu scrollable-menu">
              <li>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() => {
                    setShowModal(true)
                  }}
                >
                  <i className="bi bi-person-fill-add"></i> Add Someone{" "}
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() => {
                    setShowContactGroupModal(true)
                  }}
                >
                  <i className="bi bi-people-fill"></i> Add an Audience / Group{" "}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Detail
