import React, { useEffect, useState } from "react"
import Tags from "../../Doc/MoreOptions/tags"

const ThenBlock = ({ index, deleteThenBlock, thenBlock, setThenBlocks, messages }) => {
  const [action, setAction] = useState(""),
    [direction, setDirection] = useState(""),
    [tagScoreStep, setStep] = useState(50),
    [selectedMessage, setSelectedMessage] = useState(0),
    [tags, setTags] = useState([]),
    [tempTags, setTempTags] = useState([]),
    [branching, setBranching] = useState("Yes")

  const updateAction = e => {
    setAction(e.target.value)
    setThenBlocks(blocks => {
      return blocks.map((block, i) => {
        return i === index ? { ...block, action: e.target.value } : block
      })
    })
  }

  const updateDirection = e => {
    setDirection(e.target.value)
    setThenBlocks(blocks => {
      return blocks.map((block, i) => {
        return i === index ? { ...block, direction: e.target.value } : block
      })
    })
  }

  const updateStep = e => {
    setStep(e.target.value)
    setThenBlocks(blocks => {
      return blocks.map((block, i) => {
        return i === index ? { ...block, step: e.target.value } : block
      })
    })
  }

  const selectMessage = e => {
    setSelectedMessage(e.target.value)
    setThenBlocks(blocks => {
      return blocks.map((block, i) => {
        return i === index ? { ...block, message: e.target.value } : block
      })
    })
  }

  useEffect(() => {
    setAction(thenBlock.action)
    setBranching(thenBlock?.branching ?? "then")
    if (thenBlock?.action === "Change Tag Score") {
      setStep(thenBlock?.step ?? 0)
      setTags(thenBlock?.tags ?? [])
    }

    if (thenBlock?.action === "Stop here and load other conversation") {
      setSelectedMessage(thenBlock?.message)
    }
  }, [])

  useEffect(() => {
    if (action === "Change Tag Score" && tags)
      setThenBlocks(blocks => {
        return blocks.map((block, i) => {
          return i === index ? { ...block, tags } : block
        })
      })
  }, [tags])

  const updateBranching = e => {
    setBranching(e.target.value)
    setThenBlocks(blocks => {
      return blocks.map((block, i) => {
        return i === index ? { ...block, branching: e.target.value } : block
      })
    })
  }

  return (
    <>
      <div className="col-lg-12 mt-2">
        <div className="row align-items-center position-relative">
          <div className="col-sm-2">
            <select className="form-select ddOpt1" onChange={updateBranching} value={branching}>
              <option value="Yes">Then</option>
              <option value="No">Else</option>
            </select>
          </div>
          <div className="col-sm-10">
            <div className="input-group">
              <select className="form-select ddOpt1" onChange={updateAction} value={action}>
                <option value="" disabled>
                  Action
                </option>
                <option value="Stop here and load other conversation">
                  Stop here &amp; load other conversation
                </option>
                <option value="Opt-Out and Stop all SMSs">Opt-Out &amp; Stop all SMSs</option>
                <option value="Confirm Appointment">Confirm Appointment</option>
                <option value="Cancel Appointment">Cancel Appointment</option>
                <option value="Pause Conversation for Human &amp; Notify Me">
                  Pause Conversation for Human &amp; Notify Me
                </option>
                <option value="Change Tag Score">Change Tag Score</option>
              </select>
              {action === "Stop here and load other conversation" && (
                <select
                  className="form-select conversation_dropdown"
                  onChange={selectMessage}
                  value={selectedMessage}
                >
                  <option value="Action">== CONVERSATIONS ==</option>
                  {messages.map(msg => {
                    return (
                      <option value={msg.id} key={msg.id}>
                        {(msg.name !== "" ? msg.name : undefined) ?? msg.messageMain}
                      </option>
                    )
                  })}
                </select>
              )}
            </div>
            <a href="#" className="btnRemoveResponseRuleCondition" onClick={e => deleteThenBlock(index, e)}>
              <i className="bi bi-x-lg"></i>
            </a>
          </div>
        </div>
      </div>
      {action === "Change Tag Score" && (
        <div className="col-lg-12 mt-2 tagScoreInputOpt">
          <div className="row row-cols-lg-auto g-2 align-items-center">
            <div className="col-12">
              <select
                name="dataHandlingVisitorTagValues"
                className="form-select"
                value={direction}
                onChange={updateDirection}
              >
                <option value="Increase">Increase</option>
                <option value="Decrease">Decrease</option>
              </select>
            </div>
            <div className="col-12">visitor tag-value by</div>
            <div className="col-12">
              <input
                type="text"
                className="form-control tagScoreInput"
                style={{ textAlign: "right" }}
                value={tagScoreStep}
                onChange={updateStep}
              />
            </div>
            <div className="col-12">for</div>
            <div className="col-12">
              <Tags tags={tags} setTags={setTags} tempTags={tempTags} setTempTags={setTempTags} />
            </div>
            <div className="col-12">
              <a href="#" className="btnTagEditor d-none">
                <i className="bi bi-pencil-fill"></i>
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ThenBlock
