import React, { useState, useEffect } from "react"
import AxiosInstance from "../../../../services/axiosInstance"

const MessageItem = ({
  message,
  states,
  setStates,
  initResponseControl,
  responseControlData,
  setResponseControlData,
  branch,
}) => {
  const [yesMessage, setYesMessage] = useState(null),
    [noMessage, setNoMessage] = useState(null)

  const formatTime = time => {
    if (time) {
      const timeType = time.time === "1" ? time.timeType : `${time.timeType}s`
      return `${time.time} ${timeType} ${time.timeWhen === "before" ? "prior" : "later"}`
    }

    return ""
  }

  const getDockIcon = dock => {
    const iconDict = {
      chat: "chat-dots",
      email: "envelope",
      sms: "phone",
      call: "telephone",
    }
    return `bi bi-${dock ? iconDict[dock] : "arrow-counterclockwise"}-fill`
  }

  const deleteMessage = async e => {
    e.preventDefault()
    try {
      const response = await AxiosInstance.delete(`drive/sequence/message/${message.id}`)
      if (response.status === 200) {
        console.log("Delete success")
        setStates(oldStates => {
          const newMessages = oldStates.messages.filter(msg => msg.id !== message.id)
          return { ...oldStates, messages: newMessages }
        })
      } else {
        console.error("Unexpected status code:", response.status)
      }
    } catch (error) {
      if (error.response) {
        console.error("Server Error:", error.response.data)
      } else if (error.request) {
        console.error("No response received:", error.request)
      } else {
        console.error("Request setup error:", error.message)
      }
    }
  }

  const editMessage = e => {
    e.preventDefault()

    setStates(states => {
      const dockDict = {
        chat: "Chat",
        email: "Email",
        sms: "SMS",
        call: "Call",
      }
      return { ...states, shownDock: dockDict[message.msgType.toLowerCase()], selectedMessage: message.id }
    })
  }

  const hasResponseControl = () => {
    return (
      (message?.metaJson?.responseControls?.length ?? false) &&
      message?.metaJson?.responseControls?.length !== 0
    )
  }

  useEffect(() => {
    if (!hasResponseControl() || branch !== undefined) return

    let [branchingYes, branchingNo] = [0, 0]

    message?.metaJson?.responseControls?.map(rc => {
      rc.thenBlocks.map(tb => {
        if (tb.message !== null) {
          if (tb.branching === "Yes") branchingYes = tb.message
          else branchingNo = tb.message
        }
      })
    })

    states.messages.map(msg => {
      if (msg.id === parseInt(branchingYes)) {
        setYesMessage(msg)
      }
      if (msg.id === parseInt(branchingNo)) {
        setNoMessage(msg)
      }
    })
  }, [])

  const renderBranchings = () => {
    return [yesMessage, noMessage].map((msg, i) => {
      if (msg)
        return (
          <MessageItem
            message={msg}
            key={`${msg.id}${message.id}`}
            states={states}
            setStates={setStates}
            responseControlData={responseControlData}
            setResponseControlData={setResponseControlData}
            initResponseControl={initResponseControl}
            branch={yesMessage?.id === msg.id ? "Yes" : "No"}
          />
        )
    })
  }

  return (
    <>
      <li
        className={`other chatDockAfterEventBox ${
          hasResponseControl() ? "attachedResponseControlContainer" : ""
        } ${branch ? `chatDockResponseControlsConnected${branch}` : ""}`}
      >
        <div className={`chat-msg ${branch ? "w-100" : ""}`}>
          <div className="chatDockEventTypeBoxContainer">
            <a
              href="#"
              className="btnOpenChatDockEmail"
              data-value="email@SwiftCloud.com"
              data-bs-toggle="tooltip"
              data-bs-title="Edit"
              data-bs-original-title=""
              title=""
            >
              <span
                className="chatDockEventTypeBoxIcon"
                data-bs-toggle="tooltip"
                data-bs-title="Email"
                data-bs-original-title=""
                title=""
              >
                <i className={getDockIcon(message.msgType)}></i>
              </span>
              <span className="chatDockEventTypeBoxText">{formatTime(message?.metaJson?.time)}</span>
            </a>
          </div>
          <ul className="nav nav-tabs chatDockTopActionContainer ui-sortable" role="tablist">
            <li className="nav-item">
              <a
                href="#"
                className="nav-link btnOpenChatDockEmail"
                data-value="email@SwiftCloud.com"
                data-bs-toggle="tooltip"
                data-bs-title="Edit"
                data-bs-original-title=""
                title=""
                onClick={editMessage}
              >
                <i className="bi bi-pencil-fill"></i>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="#responseControlModal"
                className="nav-link"
                onClick={e => initResponseControl(message.id, message.metaJson, e)}
              >
                <span data-bs-toggle="tooltip" data-bs-title="Reply" data-bs-original-title="" title="">
                  <i className="bi bi-reply-fill"></i>
                </span>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="#"
                className="nav-link"
                data-bs-toggle="tooltip"
                data-bs-title="Delete"
                data-bs-original-title=""
                title=""
                onClick={deleteMessage}
              >
                <i className="bi bi-trash-fill"></i>
              </a>
            </li>
          </ul>
          <div className="avatar">
            <a
              href="#"
              className="d-block"
              data-bs-toggle="tooltip"
              title=""
              data-bs-original-title="SwiftCloud"
              aria-label="SwiftCloud"
            >
              <img
                src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/swift_cloud_flag.jpg`}
                className="mCS_img_loaded"
              />
            </a>
          </div>
          <div className="msg">
            <p>
              <strong>{message?.name ?? ""}</strong>
            </p>
            <p>{message.messageMain}</p>
          </div>
        </div>
      </li>

      {hasResponseControl() && (
        <>
          <li className="self chatDockAfterEventBox chatDockResponseControlContainer">
            <div className="chat-msg">
              <div className="avatar">
                <a
                  href="#"
                  className="d-block"
                  data-bs-toggle="tooltip"
                  title=""
                  data-bs-original-title="Future Recipient"
                  aria-label="Future Recipient"
                >
                  <img
                    src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/avatar.gif`}
                    className="mCS_img_loaded"
                  />
                </a>
              </div>
              <div className="msg">
                <div
                  className="chatDockResponseControlAction"
                  onClick={e => initResponseControl(message.id, message.metaJson, e)}
                >
                  <span
                    className="chatDockResponseControlBoxIcon"
                    data-bs-toggle="tooltip"
                    data-bs-title="Email"
                    data-bs-original-title=""
                    title=""
                  >
                    <i className="bi bi-reply-fill"></i>
                  </span>
                  <span className="chatDockResponseControlBoxText">Response Controls</span>
                </div>
              </div>
            </div>
          </li>
          {renderBranchings()}
        </>
      )}
    </>
  )
}

export default MessageItem
