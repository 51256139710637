import * as React from "react"
import moment from "moment"

import AxiosInstance from "../../../services/axiosInstance"
import { useState, useEffect } from "react"
import { Modal } from "react-bootstrap"

const AddContactModal = ({ showModal, setShowModal, sequenceId }) => {
  const [contactId, setContactId] = useState(""),
    [userSearchResult, setUserSearchResult] = useState([]),
    [userDefaultName, setUserDefaultName] = useState(""),
    [userDefaultId, setUserDefaultId] = useState(""),
    [userDefaultImage, setUserDefaultImage] = useState(""),
    [uploadedFilePath, setUploadedFilePath] = useState("StaticImages/avatar.gif"),
    [isDefaultData, setIsDefaultData] = useState(false)

  const handleSubmit = () => {
    if (contactId === "") {
      alert("Please select Contact.")
      return false
    }

    return new Promise(async () => {
      try {
        await AxiosInstance.post(`drive/sequence/assign-sequence-contact/${sequenceId}/${contactId}`)
        setShowModal(false)
        alert("Contact is added to this sequence.")
        setIsDefaultData(true)
      } catch (error) {
        if (error.response.status === 400) {
          setIsDefaultData(true)
          alert(error.response.data.message)
        }
        console.error(error)
        // reject(error)
      }
    })
  }

  const handleClose = () => {
    setShowModal(false)
  }

  const contactSearchData = e => {
    let searchUser = e.target.value
    if (searchUser.length < 2) {
      setUserSearchResult([])
      setContactId("")
      return false
    }

    AxiosInstance.get(`/contact/`, {
      params: {
        page: 1,
        search: searchUser ?? "",
      },
    }).then(response => {
      const displayedItems = response.data.items.slice(0, 10)
      setUserSearchResult(displayedItems)
    })
  }

  useEffect(() => {
    if (isDefaultData) {
      setContactId("")
      setUserDefaultName("")
      setUserDefaultImage("")
      setUserSearchResult([])
      setUserDefaultId("")
      setIsDefaultData(false)
    }
  }, [isDefaultData])

  return (
    <Modal show={showModal} id="addNewContactModal">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="addSomeoneModalLabel">
          <i className="bi bi-person-fill-add" /> Add Someone
        </h1>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={() => handleClose()}
        />
      </div>
      <div className="modal-body BC_Multiple_User">
        <div>
          <div className="bc-wrapper">
            <input type="hidden" id="userId" name="userId" defaultValue={userDefaultId} />

            <input
              type="text"
              className={`form-control socialSearchInput${contactId ? " userSelectedInput" : ""}`}
              name="user"
              placeholder="Any email or Username"
              autoComplete="off"
              onKeyUp={contactSearchData}
              data-query={userDefaultName}
              value={userDefaultName}
              onChange={e => setUserDefaultName(e.target.value)}
            />

            {userSearchResult.length > 0 && (
              <div className="bc-menu list-group" style={{ display: "block" }}>
                {userSearchResult.map((searchUserData, searchUserIndex) => {
                  return (
                    <a
                      // href="#"
                      className="list-group-item"
                      data-id={searchUserData.id}
                      data-label={searchUserData.name}
                      key={searchUserIndex}
                      onClick={() => {
                        const defaultImageUrl = `${process.env.GATSBY_WRANGLER_URL}/${
                          searchUserData.profilePic ? searchUserData.profilePic : uploadedFilePath
                        }`

                        setContactId(searchUserData.id)
                        setUserDefaultName(searchUserData.name)
                        setUserDefaultImage(defaultImageUrl)
                        setUserSearchResult([])
                        setUserDefaultId(searchUserData.id)
                      }}
                    >
                      <img
                        className="bc-user-avatar"
                        src={`${process.env.GATSBY_WRANGLER_URL}/${
                          searchUserData.profilePic ? searchUserData.profilePic : uploadedFilePath
                        }`}
                      />
                      {searchUserData.name}
                    </a>
                  )
                })}
              </div>
            )}

            {contactId && <img className="selectedUserAvatar" src={userDefaultImage} alt={userDefaultName} />}

            <div className="bc-menu list-group" style={{ display: "none" }} />
          </div>
        </div>
        <div className="my-3 row align-items-center bootcomplete_add_new_user_field d-none">
          <div className="col-lg-12 text-center">
            <div className="SubContactAvatar">
              <input
                type="file"
                accept="image/*"
                className="subContactAvatarFile"
                style={{ visibility: "hidden", position: "absolute" }}
              />
              <a
                href="javascript:;"
                className="btn btn-default btnEditSubContactAvatar"
                data-bs-toggle="tooltip"
                title=""
                data-bs-original-title="Select avatar"
                aria-label="Select avatar"
              >
                <i className="bi bi-pencil-fill" />
              </a>
              <img src="images/avatar.gif" />
            </div>
          </div>
        </div>
        <div className="mb-3 row align-items-center bootcomplete_add_new_user_field d-none">
          <div className="col-sm-12">
            <div className="input-group">
              <div className="dropdown nameOrCompanyDropdown">
                <button
                  className="btn btn-secondary dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="bi bi-person-fill" /> Name
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="#">
                      <i className="bi bi-person-fill" /> Name
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      <i className="bi bi-building-fill" /> Company Name
                    </a>
                  </li>
                </ul>
              </div>
              <input type="text" className="form-control" />
            </div>
          </div>
        </div>
        <div className="mb-3 row align-items-center bootcomplete_add_new_user_field d-none">
          <label className="col-sm-3 col-form-label text-end">Role</label>
          <div className="col-sm-9">
            <input type="text" className="form-control" />
          </div>
        </div>
        <div className="mb-3 row align-items-center bootcomplete_add_new_user_field d-none">
          <label className="col-sm-3 col-form-label text-end">Email</label>
          <div className="col-sm-9">
            <input type="email" className="form-control" />
          </div>
        </div>
        <div className="row align-items-center bootcomplete_add_new_user_field d-none">
          <label className="col-sm-3 col-form-label text-end">Phone</label>
          <div className="col-sm-9">
            <input type="tel" className="form-control" />
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-default"
          data-bs-dismiss="modal"
          onClick={() => handleClose()}
        >
          <i className="bi bi-x-lg" /> Close
        </button>
        <button type="submit" className="btn btn-primary" name="assignContact" onClick={handleSubmit}>
          <i className="bi bi-check2" /> Save &amp; Add <i className="bi bi-person-fill-add" />
        </button>
      </div>
    </Modal>
  )
}

export default AddContactModal
