import React, { useState, useEffect } from "react"
import SequenceTime from "./sequenceTime"
import useSharedLogic from "../customHook"

const SmsDock = ({ states, setStates }) => {
  const [remainingChars, setRemainingChars] = useState(140)
  const {
    time,
    setTime,
    messageMain,
    setMessageMain,
    saveMessage,
    selectMessage,
    injectVariable,
    messageMainRef,
    inputFileRef,
    chooseFile,
    updateSelectedFile,
  } = useSharedLogic(states, setStates)

  const updateMessage = e => {
    setMessageMain(e.target.value)
  }

  const addMessage = async () => {
    if (messageMain === "") return
    const messageData = {
      messageMain,
      metaJson: {
        time,
      },
      msgType: states.shownDock.toLowerCase(),
    }
    saveMessage(messageData)
  }

  useEffect(() => {
    if (states.selectedMessage === 0) {
      setTime(time => {
        return { ...time, timeWhen: states.typeWhen }
      })
      return
    }

    const message = states.messages.filter(msg => {
      return msg.id === states.selectedMessage
    })[0]
    selectMessage(message)
  }, [states.selectedMessage])

  useEffect(() => {
    setRemainingChars(140 - messageMain.length)
  }, [messageMain])

  return (
    <div className="ChatDockOpt ChatDockSMSOpt">
      <div className="mb-2">
        <div className="d-flex align-items-center">
          <div className="d-inline-block me-2">
            <button
              className="btn btnAddNewGreenDashed"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span
                data-bs-toggle="tooltip"
                title=""
                data-bs-original-title="Inject Recipient Variable i.e. Firstname"
                aria-label="Inject Recipient Variable i.e. Firstname"
              >
                <i className="bi bi-person-bounding-box"></i>
              </span>
            </button>
            <ul className="dropdown-menu scrollable-menu chatDockRecipientVariables">
              <li>
                <a className="dropdown-item" href="#" onClick={injectVariable}>
                  Firstname_FirstCap
                </a>
              </li>
            </ul>
          </div>

          <SequenceTime states={states} setTime={setTime} time={time} />

          <a className="btnUseSendWindow ms-auto" data-bs-toggle="modal" href="#useSendWindowModal">
            <span
              data-bs-toggle="tooltip"
              title=""
              data-bs-original-title="Use Send Window"
              aria-label="Use Send Window"
            >
              <i className="bi bi-clock"></i>
            </span>
          </a>
        </div>
      </div>
      <div className="mb-2">
        <textarea
          className="form-control"
          id="smsMessagebox"
          placeholder="Message"
          rows="3"
          value={messageMain}
          onChange={updateMessage}
          ref={messageMainRef}
        ></textarea>
      </div>
      <div className="row">
        <div className="col-sm-8 text-start ChatDockPrivateNoteTools">
          <div className="dropdown d-inline-block btnGroupChatDockVideoMail">
            <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i
                data-bs-toggle="tooltip"
                title=""
                className="bi bi-camera-video-fill"
                data-bs-original-title="Video Mail"
                aria-label="Video Mail"
              ></i>
            </button>
            <ul className="dropdown-menu">
              <li>
                <a className="dropdown-item" href="#chatDockVideoMail" data-bs-toggle="modal">
                  <i className="bi bi-play-fill"></i> Select Existing / Template{" "}
                </a>
              </li>
              <li>
                <a className="dropdown-item btnChatDockVideoRecording" href="#">
                  <i className="bi bi-record-fill"></i> Record New{" "}
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item btnChatDockVideoMailUpload"
                  href="#"
                  onClick={e => chooseFile(e, "video")}
                >
                  <i className="bi bi-cloud-arrow-up-fill"></i> Upload{" "}
                </a>
              </li>
            </ul>
          </div>
          <a href="#">
            <i
              data-bs-toggle="tooltip"
              title=""
              className="bi bi-link-45deg"
              data-bs-original-title="Add URL"
              aria-label="Add URL"
            ></i>
          </a>
          <a href="#" onClick={e => chooseFile(e, "image")}>
            <i
              data-bs-toggle="tooltip"
              title=""
              className="bi bi-camera-fill"
              data-bs-original-title="Insert Image"
              aria-label="Insert Image"
            ></i>
          </a>
          <a
            href="#"
            className="chatNoteImg"
            data-bs-toggle="tooltip"
            title=""
            data-bs-original-title="Upload file"
            aria-label="Upload file"
            onClick={e => chooseFile(e, "file")}
          >
            <i className="bi bi-paperclip"></i>
          </a>
          <a
            href="#"
            id="ChatDockSMSEmoji"
            data-bs-toggle="tooltip"
            title=""
            data-bs-original-title="Inject Emoji"
            aria-label="Inject Emoji"
          >
            <i className="bi bi-emoji-smile"></i>
          </a>
          <span className="d-none">
            <input
              type="file"
              accept="image/*"
              className="chatNoteImgFile"
              ref={inputFileRef}
              onChange={updateSelectedFile}
            />
          </span>{" "}
          <span className="ChatDockSMSCounter">{remainingChars} remaining</span>
        </div>
        <div className="col-sm-4 text-end">
          <button type="button" className="btn btn-primary btnChatDockSendSMS" onClick={addMessage}>
            <i className="bi bi-check2"></i> Save{" "}
          </button>
        </div>
      </div>
    </div>
  )
}

export default SmsDock
