import React, { useEffect } from "react"
import useSharedLogic from "../customHook"

const ChatDock = ({ states, setStates }) => {
  const {
    messageMain,
    setTime,
    setMessageMain,
    saveMessage,
    selectMessage,
    injectVariable,
    messageMainRef,
    inputFileRef,
    chooseFile,
    updateSelectedFile,
  } = useSharedLogic(states, setStates)

  const updateMessage = e => {
    const messageMain = e.target.value
    setMessageMain(messageMain)
  }

  const addMessage = async () => {
    if (messageMain === "") return

    saveMessage({
      messageMain,
      metaJson: {},
      msgType: "chat",
    })
  }

  useEffect(() => {
    if (states.selectedMessage === 0) {
      setTime(time => {
        return { ...time, timeWhen: states.typeWhen }
      })
      return
    }

    const message = states.messages.filter(msg => {
      return msg.id === states.selectedMessage
    })[0]
    selectMessage(message)
  }, [states.selectedMessage])

  return (
    <div className="ChatDockOpt ChatDockChatToOpt">
      <div className="mb-2 ChatDockEmailToOpt w-100">
        <div className="row align-items-center">
          <div className="col-sm-12">
            <div className="d-inline-block dropup">
              <button
                className="btn btnAddNewGreenDashed me-1"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span data-bs-toggle="tooltip" title="" data-bs-original-title="AImo" aria-label="AImo">
                  <i className="bi bi-robot"></i>
                </span>
              </button>
              <ul className="dropdown-menu scrollable-menu">
                <li>
                  <strong className="dropdown-item-text">== Email ==</strong>
                </li>
                <li>
                  <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                    AIDA - Attn, Interest, Desire, Action
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                    Pain, Agitate, Solve
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                    Cold Email
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                    Email Newsletter
                  </a>
                </li>
                <li>
                  <strong className="dropdown-item-text">== ARTICLES &amp; BLOG ==</strong>
                </li>
                <li>
                  <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                    Article Writer Collab Style
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                    Article Writer Title Dependent
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                    Article Rewriter (English)
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                    Article Outliner
                  </a>
                </li>
                <li>
                  <strong className="dropdown-item-text">== SOCIAL MEDIA ==</strong>
                </li>
                <li>
                  <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                    Twitter Tweets
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                    LinkedIn Posts
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                    Instagram Captions
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                    YouTube Helper
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" data-bs-toggle="modal" href="#coldEmailModal">
                    TikTok Helper
                  </a>
                </li>
              </ul>
            </div>
            <div className="d-inline-block">
              <button
                className="btn btnAddNewGreenDashed me-1"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span
                  data-bs-toggle="tooltip"
                  title=""
                  data-bs-original-title="Templates"
                  aria-label="Templates"
                >
                  <i className="bi bi-journals"></i>
                </span>
              </button>
              <ul className="dropdown-menu scrollable-menu">
                <li>
                  <a className="dropdown-item" href="#">
                    Doc 1
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Doc 2
                  </a>
                </li>
              </ul>
            </div>
            <div className="d-inline-block">
              <button
                className="btn btnAddNewGreenDashed me-1"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span
                  data-bs-toggle="tooltip"
                  title=""
                  data-bs-original-title="Snippets"
                  aria-label="Snippets"
                >
                  <i className="bi bi-pencil-square"></i>
                </span>
              </button>
              <ul className="dropdown-menu scrollable-menu">
                <li>
                  <a className="dropdown-item" href="#">
                    Snippets 1
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Snippets 2
                  </a>
                </li>
              </ul>
            </div>
            <div className="d-inline-block">
              <button
                className="btn btnAddNewGreenDashed me-1"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span
                  data-bs-toggle="tooltip"
                  title=""
                  data-bs-original-title="Inject Recipient Variable i.e. Firstname"
                  aria-label="Inject Recipient Variable i.e. Firstname"
                >
                  <i className="bi bi-person-bounding-box"></i>
                </span>
              </button>
              <ul className="dropdown-menu scrollable-menu chatDockRecipientVariables">
                <li>
                  <a className="dropdown-item" href="#" onClick={injectVariable}>
                    Firstname_FirstCap
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-2">
        <textarea
          className="form-control ChatDockEmailBox"
          placeholder="Message"
          rows="5"
          value={messageMain}
          onChange={updateMessage}
          ref={messageMainRef}
        ></textarea>
      </div>
      <div className="mb-2 text-end">
        <div className="row">
          <div className="col-sm-8 text-start ChatDockPrivateNoteTools">
            <div className="dropdown d-inline-block btnGroupChatDockVideoMail">
              <button
                className="dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i
                  data-bs-toggle="tooltip"
                  title=""
                  className="bi bi-camera-video-fill"
                  data-bs-original-title="Video Mail"
                  aria-label="Video Mail"
                ></i>
              </button>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="#chatDockVideoMail" data-bs-toggle="modal">
                    <i className="bi bi-play-fill"></i> Select Existing / Template{" "}
                  </a>
                </li>
                <li>
                  <a className="dropdown-item btnChatDockVideoRecording" href="#">
                    <i className="bi bi-record-fill"></i> Record New{" "}
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item btnChatDockVideoMailUpload"
                    href="#"
                    onClick={e => chooseFile(e, "video")}
                  >
                    <i className="bi bi-cloud-arrow-up-fill"></i> Upload{" "}
                  </a>
                </li>
              </ul>
            </div>
            <a href="#chatDockURLInjector" data-bs-toggle="modal">
              <i
                data-bs-toggle="tooltip"
                title=""
                className="bi bi-link-45deg"
                data-bs-original-title="Add URL"
                aria-label="Add URL"
              ></i>
            </a>
            <a href="#" onClick={e => chooseFile(e, "image")}>
              <i
                data-bs-toggle="tooltip"
                title=""
                className="bi bi-camera-fill"
                data-bs-original-title="Insert Image"
                aria-label="Insert Image"
              ></i>
            </a>
            <a
              href="#"
              className="chatNoteImg"
              data-bs-toggle="tooltip"
              title=""
              data-bs-original-title="Upload file"
              aria-label="Upload file"
              onClick={e => chooseFile(e, "file")}
            >
              <i className="bi bi-paperclip"></i>
            </a>
            <a
              href="#"
              id="ChatDockChatToEmoji"
              data-bs-toggle="tooltip"
              title=""
              data-bs-original-title="Inject Emoji"
              aria-label="Inject Emoji"
            >
              <i className="bi bi-emoji-smile"></i>
            </a>
            <span className="d-none">
              <input
                type="file"
                accept="image/*"
                className="chatNoteImgFile"
                ref={inputFileRef}
                onChange={updateSelectedFile}
              />
            </span>{" "}
          </div>
          <div className="col-sm-4 text-end">
            <button type="button" className="btn btn-primary btnChatDockSendChatMsg" onClick={addMessage}>
              <i className="bi bi-send-fill"></i> Save{" "}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChatDock
