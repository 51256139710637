import React, { useState, useEffect } from "react"
import AutomationLogic from "./automationLogic"
import MessageChannel from "./messageChannel"
import MessageList from "./message/messageList"

const MessagePanel = ({ states, setStates, submitForm, responseControlData, setResponseControlData }) => {
  const [historyBlockHeight, setHistoryBlockHeight] = useState(window.innerHeight),
    [showMessagePanel, setShowMessagePanel] = useState(true)

  const handleResize = () => {
    let height =
      window.innerHeight -
      document.querySelector(".ChatDockHeader").clientHeight -
      document.querySelector(".ChatDockFooterContainer").clientHeight
    setHistoryBlockHeight(height)
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  useEffect(() => {
    handleResize()
  }, [states.shownDock])

  const getDockIcon = dock => {
    const iconDict = {
      Chat: "chat-dots",
      Email: "envelope",
      SMS: "phone",
      Call: "telephone",
    }
    return `bi bi-${dock ? iconDict[dock] : "arrow-counterclockwise"}`
  }

  const selectChannel = e => {
    e.preventDefault()
    const dataType = e.target.dataset.type

    if (dataType) {
      setStates({ ...states, shownDock: dataType, selectedMessage: 0 })
    }
    handleResize()
  }

  const getDockText = dock => {
    return dock ? (["Call", "History"].includes(dock) ? dock : `${dock} to`) : "History"
  }

  const closeMessagePanel = e => {
    e.preventDefault()
    setShowMessagePanel(false)
  }

  return (
    <div className="col-lg-6 col-md-12">
      <div
        className={`${showMessagePanel ? "d-none" : ""} chatDockSwitch`}
        style={{ zIndex: 10 }}
        onClick={e => setShowMessagePanel(true)}
      >
        <input type="checkbox" id="chatDockSwitch" />
        <label htmlFor="chatDockSwitch" className="checkmark"></label>
      </div>
      <div className={`SCChatDockNonSocialAssetOnly ${showMessagePanel ? "d-lg-block" : "d-none"}`}>
        <div className="ChatDockHeader">
          <div className="user_info">
            <img
              src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/avatar.gif`}
              className="img-fluid"
              alt="John Doe"
            />{" "}
            Future Recipient{" "}
            <span
              data-bs-toggle="tooltip"
              title="These are message templates, and you can personalize them using shortcodes like {Firstname_FirstCap} - so they'll see 'Hi Bob!' etc."
            >
              <i className="bi bi-question-circle-fill"></i>
            </span>
          </div>
          <div className="ChatDockInputOptContainer">
            <div className="dropdown ChatDockInputOpt">
              <button
                className="btn btn-secondary dropdown-toggle"
                type="button"
                data-bs-toggle={states.isLoaded && states.metaJson?.automationLogic ? "dropdown" : ""}
                aria-expanded="false"
              >
                <i className={getDockIcon(states.shownDock)}></i> {getDockText(states.shownDock)}{" "}
              </button>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" data-type="History" href="#" onClick={selectChannel}>
                    <i className="bi bi-arrow-counterclockwise"></i> History{" "}
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    data-type="Chat"
                    data-value=""
                    href="#"
                    onClick={selectChannel}
                  >
                    <i className="bi bi-chat-dots-fill"></i> Chat{" "}
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    data-type="Email"
                    data-value=""
                    href="#"
                    onClick={selectChannel}
                  >
                    <i className="bi bi-envelope-fill"></i> Email{" "}
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" data-type="SMS" data-value="" href="#" onClick={selectChannel}>
                    <i className="bi bi-phone-fill"></i> SMS{" "}
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    data-type="Call"
                    data-value=""
                    href="#"
                    onClick={selectChannel}
                  >
                    <i className="bi bi-telephone-fill"></i> Call{" "}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <a
            href="#"
            className="btnCloseChatDock"
            data-bs-toggle="tooltip"
            title=""
            data-bs-original-title="Close"
            aria-label="Close"
            onClick={closeMessagePanel}
          >
            <i className="bi bi-x-lg"></i>
          </a>
        </div>
        <div
          className="ChatDockHistoryContainer EventTypeNameTitleNotSetup mCustomScrollbar _mCS_1 mCS_no_scrollbar"
          style={{ height: historyBlockHeight }}
        >
          <div id="mCSB_1" className="mCustomScrollBox mCS-dark mCSB_vertical mCSB_inside" tabIndex="0">
            <div id="mCSB_1_container" className="mCSB_container mCS_y_hidden mCS_no_scrollbar_y" dir="ltr">
              {states.isLoaded && !states.metaJson?.automationLogic && (
                <AutomationLogic states={states} setStates={setStates} triggerSave={submitForm} />
              )}
              {states.metaJson?.automationLogic && (
                <MessageList
                  states={states}
                  setStates={setStates}
                  selectChannel={selectChannel}
                  responseControlData={responseControlData}
                  setResponseControlData={setResponseControlData}
                />
              )}
            </div>
            <div
              id="mCSB_1_scrollbar_vertical"
              className="mCSB_scrollTools mCSB_1_scrollbar mCS-dark mCSB_scrollTools_vertical d-none"
              style={{ display: "none" }}
            >
              <div className="mCSB_draggerContainer">
                <div id="mCSB_1_dragger_vertical" className="mCSB_dragger">
                  {" "}
                  {/* onContextMenu={return false;} s tyle="position: absolute; min-height: 30px; height: 0px; top: 0px;"*/}
                  <div className="mCSB_dragger_bar"></div>
                  {/*s tyle="line-height: 30px;"*/}
                  <div className="mCSB_draggerRail"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <MessageChannel states={states} setStates={setStates} />
      </div>
    </div>
  )
}

export default MessagePanel
