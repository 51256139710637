import React from "react"
import ChatDock from "./channel/chatDock"
import SmsDock from "./channel/smsDock"
import EmailDock from "./channel/emailDock"

const MessageChannel = ({ states, setStates }) => {
  return (
    <div className="ChatDockFooterContainer">
      {states.shownDock == "Chat" && <ChatDock states={states} setStates={setStates} />}
      {["SMS", "Call"].includes(states.shownDock) && <SmsDock states={states} setStates={setStates} />}
      {states.shownDock == "Email" && <EmailDock states={states} setStates={setStates} />}
    </div>
  )
}

export default MessageChannel
